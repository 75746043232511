/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);

export function cheersSplit() {
  var introTL = gsap. timeline() ;
  var split = new SplitText(".statement__copy p", {type:"lines"});
  introTL.from(split.lines, {opacity: 0, y: "50%", duration: 1.5, ease: "power4.out", stagger: 0.1});
}
