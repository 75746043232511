/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { locoScroll } from "./smooth-scrolling";
gsap.registerPlugin(ScrollTrigger);

export function timelineScroll() {

  let pinWrap = document.querySelector(".timeline__slider");
  let pinWrapWidth = pinWrap.offsetWidth;
  let horizontalScrollLength = pinWrapWidth - window.innerWidth;

  // Pinning and horizontal scrolling

  gsap.to(".timeline__slider", {
    scrollTrigger: {
      scroller: '.smooth-scroll', //locomotive-scroll
      scrub: true,
      trigger: ".timeline",
      pin: true,
      // anticipatePin: 1,
      start: "top top",
      end: pinWrapWidth
    },
    x: -horizontalScrollLength,
    ease: "none"
  });

  ScrollTrigger.addEventListener("refresh", () => locoScroll.update()); //locomotive-scroll

  ScrollTrigger.refresh();

}

