/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function animateOnScroll() {
  // any element with animateOnLoad class
  // this way would then let you handle animation in css
  ScrollTrigger.batch(".animateOnLoad", {
    scroller: ".smooth-scroll",
    toggleClass: "inView",
    once: true
  });
}
