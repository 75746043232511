/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, SplitText);

export function heroSplitText() {
  var mySplitText = new SplitText(".hero__title", {type:"chars, words"});
  var numChars = mySplitText.chars.length;
  var tl = gsap.timeline();
  // gsap.from(heroTextChars, {
  //   opacity: 0,
  //   stagger: 0.1
  // });

  for(var i = 0; i < numChars; i++){
    //random value used as position parameter
    tl.from(mySplitText.chars[i], 0.5, {
      opacity: 0,
      ease: "Power2.out",
      y: random(-25, 25),
    }, Math.random(0.15, 0.25));
  }

  function random(min, max) {
    if (max == null) { max = min; min = 0; }
    return function() {
      return Math.random() * (max - min) + min;
    }
  }

}
